import React, { Component } from 'react'
import QrReader from 'modern-react-qr-reader'
import {targetHost} from "../utils/constants";
import {targetProtocol} from "../utils/constants";

class QRScanner extends Component {
  constructor(props) {
        super(props);

        this.state = {
            result: '',
            tokenType: '',
            qrFound: false,
            offerRepeat: false,
            scanSuccess: 'Please scan a valid QR code'
        }

        this.handleError = this.handleError.bind(this);
        this.handleScan = this.handleScan.bind(this);
        this.scanagain = this.scanagain.bind(this);
        this.qrPressed = this.qrPressed.bind(this);
    }

  handleScan = data => {
    if (data) {
      this.state.result = data;
      const tokenTypeExtract = data.substr(data.indexOf('tokentype=')+10, 3).toUpperCase();
        console.log(this.state.result);
        this.setState({result: data});
        this.setState({tokenType: tokenTypeExtract});
        this.setState({qrFound: true});
    }
  }
  
  handleError = err => {
    console.error(err)
  }

  async qrPressed() { 
    
    await fetch(
      `${this.state.result}`
    )
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.length > 0) {
          const successState = data;
          this.setState({
            scanSuccess: successState
          });
          console.log("Attempt is: "+this.state.scanSuccess);
        }
      })
      .catch(error => {
        console.log(error);
      });
      
    this.setState({ 
      offerRepeat: true
    });
  }
  
  scanagain() { 
    
    this.setState({ 
      qrFound: false
    });
  }
  
  render() {
    return (
      <div>
        <p>{this.state.scanSuccess}</p>
        {!this.state.qrFound &&
          <QrReader
            delay={300}
            facingMode={"environment"}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%' }}
          />
        }
        {this.state.qrFound && !this.state.offerRepeat && 
          <div>
          <p>{this.state.tokenType} Token Found</p>
          <button onClick={() => this.qrPressed()}>
                      Cash Token
          </button>
          </div>
        }
        {this.state.qrFound && this.state.offerRepeat && 
          <div>
          <button onClick={() => this.scanagain()}>
                      Scan Again
          </button>
          </div>
        }
      </div>
    )
  }
}

export default QRScanner;