import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faArrowRightToBracket, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Flex, Table, TableCell, TableRow } from '@aws-amplify/ui-react';

function BottomBar() {

  const navigate = useNavigate();

  const navigateToSend = () => {
    navigate('/');
  };

  const navigateToScan = () => {
    navigate('/scantoken');
  };

  const navigateToTopUp = () => {
    navigate('/topup');
  };

  return (
    <div style={{ backgroundColor: '#B3CCF2', color: 'white', position: 'fixed', bottom: 0, width: '100%', height: '70px', textAlign: 'center', maxWidth: '800px', margin: '0 auto' }}>
      <Flex direction="column" border="none" alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Table>
        <TableRow verticalAlign="top">
          <TableCell style={{ textAlign: 'left' }}>
        <button style={{ border: 'none', background: 'transparent', cursor: 'pointer', marginRight: '10px' }} onClick={navigateToSend}>
          <FontAwesomeIcon icon={faArrowRightFromBracket} size="2x" style={{ color: 'white', transform: 'rotate(-90deg)' }} />
        </button>
        </TableCell>
        <TableCell style={{ textAlign: 'middle' }}>
        <button style={{ border: 'none', background: 'transparent', cursor: 'pointer', marginRight: '10px' }} onClick={navigateToScan}>
          <FontAwesomeIcon icon={faArrowRightToBracket} size="2x" style={{ color: 'white', transform: 'rotate(90deg)' }} />
        </button>
        </TableCell>
        <TableCell style={{ textAlign: 'right' }}>
        <button style={{ border: 'none', background: 'transparent', cursor: 'pointer', marginRight: '10px' }} onClick={navigateToTopUp}>
          <FontAwesomeIcon icon={faPlus} size="2x" style={{ color: 'white' }} />
        </button>
        </TableCell>
        </TableRow>
        </Table>
        </Flex>
    </div>
  );
}

export default BottomBar;
