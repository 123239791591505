import React, { useRef, useState } from 'react';
//import '@aws-amplify/ui-react/styles.css';
import { QRCodeSVG } from 'qrcode.react';

import {targetHost} from "../utils/constants";
import {targetProtocol} from "../utils/constants";
import { Flex, Table, TableCell, TableRow } from '@aws-amplify/ui-react';
import 'date-fns/locale/en-US';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Clipboard from "clipboard-polyfill";
import copy from 'copy-to-clipboard';

import Print from "../images/sample-avatar.png";

class SendToken extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      tokensOwnedUser: [],
      heldTokens: [],
      userEmail: "",
      userJWTToken: "",
      userAccount: "",
      showQRCode: false,
      showQRReader: false,
      currentQRCode: "",
      userNickname: "",
      dateCur: "",
      dayCur: "",
      isCopied: false,
      tokenToRet: "Pa"
    };

    this.idtPressed = this.idtPressed.bind(this);
    this.qrPressed = this.qrPressed.bind(this);
  }

  async componentDidMount() {

    const { user } = this.props;
    const jwtToken = user.signInUserSession.idToken.jwtToken;

    console.log('User Attributes: '+user.attributes);

    this.setState({ 
      userEmail: user.attributes.email
    });

    this.setState({ 
      userJWTToken: jwtToken
    });

    const axios = require('axios');

    console.log("Hub stuff set up - user email is: "+user.attributes.email);

    //Now get the account for the user
    await fetch(
      `${targetProtocol}://${targetHost}/getaccount?email=${user.attributes.email}`, {
        headers: {
            'Authorization': `Bearer ${jwtToken}`
        }
    }
    )
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.length > 0) {
          const accountMapTemp = data[0].accountid;
          this.setState({
            userAccount: accountMapTemp
          });
          console.log("Account here is: "+accountMapTemp);
          //Now list all the tokens for the user
          fetch(
            `${targetProtocol}://${targetHost}/listalltokens?account=${accountMapTemp}`, {
              headers: {
                  'Authorization': `Bearer ${jwtToken}`
              }
          }
          )
            .then(res => {
              return res.json();
            })
            .then(data => {
              let tokensOwnedUserFromApi = data.map((tokensHeld) => {
                return { token_type: tokensHeld.token_type, num_tokens: tokensHeld.num_tokens, currentNFT: tokensHeld.currentNFT,
                };  
              }); 
              this.setState({ 
                heldTokens: tokensOwnedUserFromApi
              });
              console.log("No of types of tokens held is: "+tokensOwnedUserFromApi.length);
            })
            .catch(error => {
              console.log(error);
            });
        }
      })
      .catch(error => {
        console.log(error);
      });

      console.log("Account stored is: "+this.state.userAccount);

      console.log("No of types of tokens held global is: "+this.state.heldTokens.length);

    //Now get the account for the user
    await fetch(
      `${targetProtocol}://${targetHost}/nickname?email=${user.attributes.email}`, {
        headers: {
            'Authorization': `Bearer ${jwtToken}`
        }
    }
    )
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.length > 0) {
          const nicknameRet = data[0].nickname;
          this.setState({
            userNickname: nicknameRet
          });
          console.log("Nickname is: "+this.state.userNickname);
        }
      })
      .catch(error => {
        console.log(error);
      });

      const currentDate = new Date();

      // Format the date as "31.10"
      const formattedDate = currentDate.getDate().toString().padStart(2, '0')+"."+(currentDate.getMonth()+1).toString().padStart(2, '0');
      //const formattedDate = format(currentDate, 'dd.MM');

      // Get the day in 3-letter form (e.g., "Sun", "Mon", etc.)
      const dayArray = ['Sun','Mon', 'Tue', 'Wed','Thu','Fri','Sat'];
      const dayInThreeLetters = dayArray[currentDate.getDay()];

      this.setState({
        dateCur: formattedDate,
        dayCur: dayInThreeLetters
      });

  }
  

  async idtPressed(tokenT, curNFT) {  

    console.log("Token Type after button press: "+tokenT);
    console.log("Current NFT: "+curNFT);

    this.setState({
      currentQRCode: `${targetProtocol}://${targetHost}/transferburn?accountfrom=${this.state.userAccount}&tokentype=${tokenT}&tokenid=${curNFT}`
    });
      
    this.setState({ 
      showQRCode: true
    });
   
  }

  idtCopyPressed(tokenT, curNFT) {  

    console.log("Token Type after button press: "+tokenT);
    console.log("Current NFT: "+curNFT);
      
    
    const startDate = new Date();
    const toCopyNFT = `Test ${this.state.userJWTToken} Test ${targetProtocol}://${targetHost}/transferburn?accountfrom=${this.state.userAccount}&tokentype=${tokenT}&tokenid=${curNFT}&startdate=${startDate}`

    //  const bigT = this.dummyMethod(tokenT);

    //  bigT.then(result => {
    //    console.log(result)
    //    copy(result)
    //  }).catch(error => {
    //    console.log(error)
    //  })

    copy(toCopyNFT);
    
  }

  qrPressed() {  
      
    this.setState({ 
      showQRCode: false
    });

    console.log("QR Closed");

  }

  readQRPressed() {  
      
    this.setState({ 
      showQRCode: false,
      showQRReader: true
    });

    console.log("QR Closed");

  }

  triggerAPIAddUser = (username, email) => {
    
    const apiUrl = '${targetProtocol}://${targetHost}/registeruser';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, email })
    };

    fetch(apiUrl, requestOptions) //Doing as suggested by the droids
    //fetch(
    //  `http://18.170.34.201:3001/registeruser?username=${username}&email=${email}`
    //)
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log('User Added Successfully');
        // Handle the response from the API endpoint
      })
      .catch(error => {
        console.log(error);
      });

  };

  render() {
    const { isAuthenticated } = this.state;
    const { user } = this.props;

    if (this.state.heldTokens.length === 0) {
      return <div>Loading...</div>; // Display a loading state
    }

    return (
      <main>
            <div style={{ fontSize: '2em', fontWeight: 700, color: '#0A4C99', fontFamily: 'BioSans' }}>Hi {this.state.userNickname}</div>
          <Flex direction="column">
            <Table>
            <TableRow verticalAlign="top">
              <TableCell style={{ verticalAlign: 'top', textAlign: 'left', border: 'none', fontSize: '4em', fontWeight: 600, color: '#0A4C99', fontFamily: 'BioSans' }}>
                {this.state.dateCur}
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'right', border: 'none', fontSize: '4em', fontWeight: 600, color: '#0A4C99', fontFamily: 'BioSans' }}>
                {this.state.dayCur}
            </TableCell>
            </TableRow>
            </Table>
          </Flex>
          <div className="mini-logo-container">
            <img src={Print} alt="logo" className="img-fluid" width="250" />
          </div>
          <div>
            {!this.state.showQRCode && !this.state.showQRReader && this.state.heldTokens.map((item, index) => {
              return (
                <div key={index}>
                  <button className="button-8" onClick={() => this.idtPressed(item.token_type, item.currentNFT)}>
                    {item.token_type} ({item.num_tokens})
                  </button>&nbsp;&nbsp;&nbsp;
                    <button className="button-copy" onClick={() => this.idtCopyPressed(item.token_type, item.currentNFT)}>
                    <FontAwesomeIcon icon={faCopy} size="1.5x" style={{ color: '#0A4C99' }} />
                  </button>
                </div>
              );
            })}
            {this.state.showQRCode && !this.state.showQRReader && <button className='ghostbutton' onClick={() => this.qrPressed()}>
                <QRCodeSVG
                value={this.state.currentQRCode}
                size={250}
                fgColor="#0A4C99"
                color="#0A4C99"
                backgroundcolor="white"
              />
              </button>
            }
          </div>
          <br></br><br></br><br></br><br></br><br></br><br></br><br></br>

          
      </main>
    );
  }
}

export default SendToken;
