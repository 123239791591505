import React, { useEffect, useState } from 'react';
import Splash from "./HUtilted.png";

const ImageSplash = ({ onFinish }) => {
  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(false);
      onFinish();
    }, 1500);

    return () => clearTimeout(timer);
  }, [onFinish]);

  return (
    <div
      style={{
        display: showImage ? 'block' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'white',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={Splash}
        alt=""
        style={{
          maxWidth: '75%',
          maxHeight: '75%',
          animation: showImage ? 'fade-in 1.5s forwards' : '',
        }}
      />
    </div>
  );
};

export default ImageSplash;
