import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBell } from '@fortawesome/free-solid-svg-icons'
import { Flex, Table, TableCell, TableRow } from '@aws-amplify/ui-react';

function TopBar() {

  const navigate = useNavigate();

  const navigateToAccount = () => {
    navigate('/account');
  };

  const navigateToNotify = () => {
    navigate('/notify');
  };

    return (
      <div style={{ backgroundColor: 'white', color: 'white', position: 'fixed', top: 0, width: '100%', height: '70px', textAlign: 'center', maxWidth: '800px', margin: '0 auto', border: 'none'}}>
        <Flex direction="column" border="none" alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Table>
        <TableRow verticalAlign="top">
          <TableCell style={{ textAlign: 'left', border: 'none' }}>
        <button style={{ border: 'none', background: 'transparent', cursor: 'pointer', marginRight: '10px' }} onClick={navigateToAccount}>
        <FontAwesomeIcon icon={faUser} size="2x" style={{ color: '#0A4C99' }}/>
        </button>
        </TableCell>
        <TableCell style={{ textAlign: 'right', border: 'none' }}>
        <button style={{ border: 'none', background: 'transparent', cursor: 'pointer', marginRight: '10px' }} onClick={navigateToNotify}>
        <FontAwesomeIcon icon={faBell} size="2x" style={{ color: '#0A4C99' }}/>
        </button>
        </TableCell>
        </TableRow>
        </Table>
        </Flex>
      </div>
  );
}

export default TopBar;
