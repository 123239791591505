import React from 'react';
import '@aws-amplify/ui-react/styles.css';

class TopUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {

    console.log('Check it out');

  }

  render() {
    const { isAuthenticated } = this.state;
    const { user } = this.props;

    return (
      <main>
          <div>
            Embedded shopping cart of tokens and payment options here
          </div>
      </main>
    );
  }
}

export default TopUp;