import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ImageSplash from './pages/ImageSplash';
import ScanToken from './pages/ScanToken';
import Account from './pages/Account';
import Notify from './pages/Notify';
import SendToken from './pages/SendToken';
import TopUp from './pages/TopUp';
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Authenticator } from "@aws-amplify/ui-react";
import BottomBar from './pages/BottomBar';
import TopBar from './pages/TopBar';

function App() {

  const [showSplash, setShowSplash] = useState(true);

  const handleSplashFinish = () => {
    setShowSplash(false);
  };

  const paddingTop = '90px'; // Adjust the padding v

  // Scroll to top whenever the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (  
    <Authenticator>
      {({ signOut, user }) => (
        <main style={{ maxWidth: '800px', margin: '0 auto' }}>
          {showSplash && <ImageSplash onFinish={handleSplashFinish} />}
        <div className="App">
            <Router>
          <TopBar></TopBar>
          <div style={{ paddingTop }}>
              <Routes>
                <Route path="/" element={<SendToken user={user} />} />
                <Route path="/scantoken" element={<ScanToken />} />
                <Route path="/topup" element={<TopUp />} />
                <Route path="/account" element={<Account user={user} />} />
                <Route path="/notify" element={<Notify />} />
              </Routes>
            <BottomBar></BottomBar>
          </div>
            </Router>
        </div>
        </main>
      )}
    </Authenticator>
  );
}

export default withAuthenticator(App, {
  socialProviders: ['apple']
})
