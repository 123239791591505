import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Clipboard from "clipboard-polyfill";
import copy from 'copy-to-clipboard';

class Notify extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {

    console.log('Check it out');

  }

  render() {
    const { isAuthenticated } = this.state;
    const { user } = this.props;

    return (
      <main>
          <h1>Hello {this.state.userEmail}</h1>
          <h1>Hi {this.state.userAccount}</h1>
          
      </main>
    );
  }
}

export default Notify;