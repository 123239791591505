import React from 'react';
import { Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';

class Account extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {

    console.log('Check it out');


  }

  render() {
    const { isAuthenticated } = this.state;
    const { user } = this.props;

    const handleSignOut = async () => {
      try {
        await Auth.signOut();
      } catch (error) {
        console.error('Error signing out:', error);
      }
    };

    return (
      <main>
          <div>
            Account page here. Mostly verified information except nickname which will be editable.
          </div>
          <button onClick={handleSignOut}>Sign Out</button>
      </main>
    );
  }
}

export default Account;